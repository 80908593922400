import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Cta from "../../components/cta";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import * as Styles from "../../styles/effect.module.css";

const FAQ = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Frequently Asked Questions - KATSANA® Integrated Fleet Management &
          Industrial Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/frequently-asked-questions/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/frequently-asked-questions/"
        />
        <meta
          property="og:title"
          content="About Us - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            These are the collection of Frequently-Asked-Questions
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Frequently Asked Questions
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-medium">FAQ's</h3>
          <p className="mt-6 leading-7">
            These are the collection of Frequently-Asked-Questions. If your
            questions are not answered here, please feel free to contact our
            support team at support@katsana.com
          </p>

          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <div className="mt-6">
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>How do I change my password?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  <p>1. Navigate to your Account Setting page.</p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changepass1.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    2. From the Account Setting’s page, key in your new password
                    in both “Change Password” field and “Confirm Password”
                    field.
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changepass2.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    3. Click ‘Submit’ and you can now access your account using
                    the new password.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>How do I update my avatar?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  1. From the ‘Edit Profile’ screen, click on “Upload New
                  Picture”.
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/updateavatar.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    2. A new Windows Explorer windows (or Mac Finder) will
                    popup. Choose the image that you wishes to use as your
                    avatar.
                  </p>
                  <p className="mt-6">
                    3. Click Open (or OK); the new image will be uploaded and
                    will automatically be your new avatar.
                  </p>
                  <p className="mt-6 text-red-500">
                    The file size must not exceed 1MB & the system only supports
                    .jpg, .jpeg, .png, .bmg, .gif and .svg.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>How do I change email/username?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  1. Navigate to your Account Setting page.
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changepass1.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    2. Key in your new email in the ‘Email’ field.
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeemail.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    3. Click ‘Submit’ and you will receive a message denoting
                    that your account has been updated.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>How do I change my vehicle’s avatar?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  1. Login to your account and click on ‘Vehicle’.
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeavatar1.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    2. From your vehicle lists, under the column ‘Action’, click
                    on the edit button for the vehicle that you wishes to change
                    the vehicle’s image
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeavatar2.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    3. In the ‘Vehicle Information’ screen, click on ‘Media’.
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeavatar3.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    4. In the next screen, click the box under ‘Add Picture’.
                    Window Explorer (Or Mac’s Finder) will popup. Choose your
                    image by double-clicking on it; our system will starts to
                    upload it automatically.
                    <br />
                    <br />
                    *You can also drag your image from your computer and drop it
                    on the box to upload.
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeavatar4.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    5. If you have already uploaded more than 1 image, you need
                    to click on ‘Set As Avatar’ to set the image as your
                    vehicle’s image.
                  </p>
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/graphics/changeavatar5.png"
                    objectFit="contain"
                    className="mt-4"
                  />
                  <p className="mt-6">
                    6. Once done, the new image will be used; check the top-left
                    corner to verify.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>Can I set my policy from my mobile?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  <p>
                    Currently, you cannot set your policies using mobiles.
                    Mobile apps are used primarily for tracking and receiving
                    notifications for any policy breaches. To setup your policy,
                    kindly login to your account and navigate to your policy
                    page. Should you need, you can refer to this documentation
                    or reach out to us by sending us an email at
                    support@katsana.com.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>Can I have a secondary account?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  <p>
                    Yes. Secondary account are used only to view vehicles; it
                    cannot be used to edit vehicle information nor create
                    policies. To get a secondary account, send the name, email
                    address, and phone number, to support@katsana.com.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          <Disclosure as="div">
            {({ open }) => (
              <div>
                <Disclosure.Button
                  className={`${
                    open ? "font-medium" : ""
                  } flex justify-between w-full h-14 items-center text-sm font-medium text-left border-b border-b-blue-100 text-bluetext`}
                >
                  <span>What should I do if someone took my vehicle?</span>
                  <ChevronUpIcon
                    className={`${open ? "transform rotate-180" : ""} w-5 h-5 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="py-6 text-sm ">
                  <p>
                    Remain calm, and contact our Royal Police Force, or go to
                    the nearest police station and lodge an official report. You
                    can provide them with Katsana’s tracking to assist them with
                    their investigation. Once you’ve requested help from the
                    authority, you can generate a share link and share the live
                    location of your stolen vehicle to the authorities, friends,
                    families, and the public. Learn how to generate a public
                    sharing link here.
                  </p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default FAQ;
